<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="620px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
			<div class="flex">
				<div class="flex-1">
					<el-form-item label="公司编码" prop="code">
						<el-input size="small" v-model="dataForm.code" placeholder="公司编码" maxlength="32" :disabled="changeRowDisabled('code')"></el-input>
					</el-form-item>
					<el-form-item label="公司简称" prop="nameShort">
						<el-input size="small" v-model="dataForm.nameShort" placeholder="公司简称" maxlength="32" :disabled="changeRowDisabled('nameShort')"></el-input>
					</el-form-item>
					<el-form-item label="区域" prop="rangeId">
						<el-select size="small" v-model="dataForm.rangeId" filterable placeholder="请选择区域" @change="handleArea" :disabled="changeRowDisabled('rangeId')">
							<el-option
								v-for="item in areaList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属经销商" prop="parentIds" v-show="typeVisible">
						<el-select size="small"
							ref="myselect"
							v-model="dataForm.parentIds"
							multiple
							filterable
							placeholder="请选择所属经销商"
							@focus="handleParentCode"
							@remove-tag="handleRemove"
							:disabled="changeRowDisabled('parentIds')"
							:multiple-limit="5"
                            popper-class="my-select-dropdown">
							<el-option
								v-for="item in dealerList"
								v-if="item.code !== dataForm.code"
								:key="item.id"
								:label="item.name"
								:value="item.id">
                                <span>{{ item.name }}{{ parentIdsTemp.includes(item.id) ? ' (待确认)' : '' }}</span>
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="flex-1">
					<el-form-item label="公司名称" prop="name">
						<el-input size="small" v-model="dataForm.name" placeholder="公司名称" maxlength="32" :disabled="changeRowDisabled('name')"></el-input>
					</el-form-item>

					<el-form-item label="签约级别" prop="contractLevel">
						<el-select size="small" v-model="dataForm.contractLevel" filterable placeholder="请选择签约级别" :disabled="changeRowDisabled('contractLevel')" @change="handleContractLevel">
							<el-option
								v-for="item in contractLevelList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="省市" prop="areaId" v-show="typeVisible">
						<el-select size="small" v-model="dataForm.areaId" filterable placeholder="请选择省市" @change="handleCity" :disabled="changeRowDisabled('areaId')">
							<el-option
								v-for="item in proviceList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="签约类型" prop="contractType" v-show="typeVisible">
						<el-select size="small" v-model="dataForm.contractType" filterable multiple placeholder="请选择签约类型" @change="handleContractType" :disabled="changeRowDisabled('contractType')">
							<el-option
								v-for="item in contractTypeList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="省市" prop="areaId" v-show="!typeVisible">
						<el-select size="small" v-model="dataForm.areaId" filterable placeholder="请选择省市" :disabled="changeRowDisabled('areaId')">
							<el-option
								v-for="item in proviceList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
			</div>
			<el-form-item label="公司说明" prop="remark">
                <el-input type="textarea" :rows="4" v-model="dataForm.remark" resize="none" placeholder="公司说明" maxlength="256" :disabled="changeRowDisabled('remark')"></el-input>
            </el-form-item>
			<el-form-item label="名称修改记录" prop="nameHis" v-if="nameHis.length > 0">
				<table border="1px">
					<tr>
						<td style="width: 145px">变更时间</td>
						<td style="width: 70px">变更人</td>
						<td>变更信息</td>
					</tr>
					<tr v-for="(item,i) in nameHis" :key="i" class="custom-row">
						<td>{{ item.changeTime || "1" }}</td>
						<td>{{ item.changeBy || "2" }}</td>
						<td>{{ item.changeInfo || "3" }}</td>
					</tr>
				</table>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer" v-if="changeRowDisabled('submit')">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增公司',
			visible: false,
			disable: false,
			type: '', // 新增、编辑、变更
			submitUrl: 'addCompany',
			firstFlag: true,
			typeVisible: true,
			areaList: [],
			proviceList: [],
			contractLevelList: [],
			contractTypeList: [],
			dealerList: [],
			contractTypeOrg: '', // 原有数据
			contractTypeOrgName: '', // 原有数据
			dataForm: {
				code: '',
				name: '',
				nameShort: '',
				rangeId: '',
				areaId: '',
				contractLevel: '',
				contractType: [],
				contractTypeName: '',
				remark: '',
				parentNames: '',
				parentIds: [],
			},
			dataRule: {
				code: [
					{required: true,message: '公司编码不能为空', trigger: "blur"},
					{pattern: /^[A-Za-z0-9_]*$/, message: '公司编码只能输入数字字母下划线'}
				],
				name: [
					{required: true,message: '公司名称不能为空', trigger: "blur"}
				],
				rangeId: [
					{required: true,message: '请选择区域', trigger: "change"}
				],
				areaId: [
					{required: true,message: '请选择省市', trigger: "change"}
				],
				contractLevel: [
					{required: true,message: '请选择签约级别', trigger: "change"}
				],
				// parentIds: [
				// 	{required: true,message: '请选择所属经销商', trigger: "change"}
				// ]
			},
			parentIdsFlag: false,
            parentIdsTemp: [],
			nameHis:[], // 修改历史
		}
	},
	methods: {
		init(type, data) {
			this.type = type;
			this.visible = true;
			// 获取省市数据
			this.getList();
			this.proviceList = [];
			this.nameHis = [];
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
                console.log(data)
				this.dataForm.parentIds = [];
                // 新增
				if(type == 'add') {
					this.title = '新增公司';
					delete this.dataForm.id;
					this.submitUrl = 'addCompany';
					this.disable = false;
					this.dataForm.areaId = '';
					this.typeVisible = true;
					this.getAutoCodeId();
					this.parentIdsFlag = true;
					this.dealerList = [];
					this.dataForm.contractLevel = '1410436620354142210';
				}
                // 编辑
				else if(type == 'edit') {
					data.parentIds == '' ? this.dataRule.parentIds = [] : '';
					this.title = '修改公司';
					this.submitUrl = 'updateCompany';
					this.disable = false;
					this.dataForm.id = data.id;
					this.dataForm.code = data.code;
					this.dataForm.name = data.name;
					this.dataForm.nameShort = data.nameShort;
					this.dataForm.rangeId = data.rangeId ? data.rangeId : '';
					this.dataForm.areaId = data.areaId ? data.areaId : '';
					this.dataForm.contractLevel = data.contractLevel ? data.contractLevel : '';
					this.dataForm.contractType = data.contractType ? data.contractType.split(',') : '';
					this.dataForm.contractTypeName = data.contractTypeName;
					this.contractTypeOrg = data.contractTypeOrg ? data.contractTypeOrg.split(',') : '';
					this.contractTypeOrgName = data.contractTypeOrgName;
					this.dataForm.remark = data.remark;
					this.parentIdsFlag = data.parentIds == '' ? false : true;
					this.typeVisible = (data.contractLevelName == '理光总部' || data.contractLevelName == '理光区域') ? false : true;
					this.areaList.forEach(item => {
						if(this.dataForm.rangeId == item.id) {
							this.proviceList = item.areaList;
						}
					})
                    this.parentIdsTemp = data.parentIdsTemp.split(',').filter(item => item !== "");
                    this.dataForm.parentNames = data.parentNamesTemp || data.parentNames;
					if(data.parentIds != '' || data.parentIdsTemp != '') {
						this.getDealerList();
						this.dataForm.parentIds = data.parentIds.split(',').concat(data.parentIdsTemp.split(',')).filter(item => item !== "");
					}else {
                        if(this.dataForm.areaId && this.dataForm.rangeId) {
                            this.getDealerList();
                        }
                    }
				}
				// 变更
				else if(type == 'updateAgent') {
					data.parentIds == '' ? this.dataRule.parentIds = [] : '';
					this.title = '变更';
					this.submitUrl = 'updateAgent';
					this.disable = true;
					this.dataForm.id = data.id;
					this.dataForm.code = data.code;
					this.dataForm.name = data.name;
					this.dataForm.nameShort = data.nameShort;
					this.dataForm.rangeId = data.rangeId ? data.rangeId : '';
					this.dataForm.areaId = data.areaId ? data.areaId : '';
					this.dataForm.contractLevel = data.contractLevel ? data.contractLevel : '';
					this.dataForm.contractType = data.contractType ? data.contractType.split(',') : '';
					this.dataForm.contractTypeName = data.contractTypeName;
					this.contractTypeOrg = data.contractTypeOrg ? data.contractTypeOrg.split(',') : '';
					this.contractTypeOrgName = data.contractTypeOrgName;
					this.dataForm.remark = data.remark;
					this.parentIdsFlag = data.parentIds == '' ? false : true;
					this.typeVisible = (data.contractLevelName == '理光总部' || data.contractLevelName == '理光区域') ? false : true;
					this.areaList.forEach(item => {
						if(this.dataForm.rangeId == item.id) {
							this.proviceList = item.areaList;
						}
					})
					this.parentIdsTemp = data.parentIdsTemp.split(',').filter(item => item !== "");
					this.dataForm.parentNames = data.parentNamesTemp || data.parentNames;
					if(data.parentIds != '' || data.parentIdsTemp != '') {
						this.getDealerList();
						this.dataForm.parentIds = data.parentIds.split(',').concat(data.parentIdsTemp.split(',')).filter(item => item !== "");
					}else {
						if(this.dataForm.areaId && this.dataForm.rangeId) {
							this.getDealerList();
						}
					}
				}
                // 查看
				else {
					this.title = '查看公司';
					this.submitUrl = 'updateCompany';
					this.disable = true;
					this.dataForm.id = data.id;
					this.dataForm.code = data.code;
					this.dataForm.name = data.name;
					this.dataForm.nameShort = data.nameShort;
					this.dataForm.rangeId = data.rangeId ? data.rangeId : '';
					this.dataForm.areaId = data.areaId ? data.areaId : '';
					this.dataForm.contractLevel = data.contractLevel ? data.contractLevel : '';
					this.dataForm.contractType = data.contractType ? data.contractType.split(',') : '';
					this.dataForm.contractTypeName = data.contractTypeName;
					this.dataForm.remark = data.remark;
					this.parentIdsFlag = data.parentIds == '' ? false : true;
					this.typeVisible = (data.contractLevelName == '理光总部' || data.contractLevelName == '理光区域') ? false : true;
					this.areaList.forEach(item => {
						if(this.dataForm.rangeId == item.id) {
							this.proviceList = item.areaList;
						}
					})
					if(data.parentIds != '') {
						this.getDealerList();
						this.dataForm.parentIds = data.parentIds.split(',');
					}
					this.nameHis = data.nameHis ? JSON.parse(data.nameHis || '') : [];
				}
			})
		},
		// 获取签约级别，地区等数据
		getList() {
			let that = this;
			that.$request.post(
				'getListAllByCodes',
				true,
				{
					codes: 'contractLevel,contractType,areaType'
				},
				function (r) {
					if (r.code == "0") {
						that.areaList = r.data.areaType;
						that.contractLevelList = r.data.contractLevel;
						that.contractTypeList = r.data.contractType
						if(that.firstFlag && that.dataForm.rangeId != '') {
							that.firstFlag = false;
							that.areaList.forEach(item => {
								if(that.dataForm.rangeId == item.id) {
									that.proviceList = item.areaList;
								}
							})
						}
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取公司id
		getAutoCodeId() {
			let that = this;
			that.$request.post(
				'getAutoCodeId',
				true,
				{
					code: 'agentCode'
				},
				function (r) {
					if (r.code == "0") {
						that.dataForm.code = r.data.val;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleContractLevel(val) {
			let levelName = val ? this.contractLevelList.find(ele => ele.id === val).name : '';
			this.typeVisible = (levelName == '理光总部' || levelName == '理光区域') ? false : true;
			this.dataForm.contractType = '';
			this.dataForm.contractTypeName = '';
		},
		// 签约类型
		handleContractType(val) {
			console.log(this.dataForm.contractTypeName);
			let arr = [];
			val.forEach(el => {
				arr.push(this.contractTypeList.find(ele => ele.id === el).name)
			})
			this.dataForm.contractTypeName = arr.length > 0 ? arr.join(',') : '';
		},
		// 获取省市数据
		handleArea() {
			this.dataForm.areaId = '';
			this.dataForm.parentIds = [];
			this.dealerList = [];
			this.dataForm.contractType = [];
			this.dataForm.contractTypeName = '';
			this.areaList.forEach(item => {
				if(this.dataForm.rangeId == item.id) {
					this.proviceList = item.areaList;
				}
			})
		},
		// 获取所属经销商数据
		getDealerList() {
			let that = this;
			that.$request.post(
				'getAreaAgent',
				false,
				{
					contractLevel: '1410436558597210114',
					rangeId: that.dataForm.rangeId,
					areaId: that.dataForm.areaId,
					active: '1'
				},
				function (r) {
					if (r.code == "0") {
						that.dealerList = r.data;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 修改省市
		handleCity() {
			this.dataForm.parentIds = [];
			this.dataForm.contractType = [];
			this.dataForm.contractTypeName = '';
			this.getDealerList();
		},
		// 选择所属经销商获取签约类型
		handleParentCode() {
			this.$refs.myselect.$refs.input.blur = () => {
				if(this.dataForm.parentIds.length == 0) {
					return
				}
				let parentNames = [];
				this.$refs.myselect.selected.forEach(item => {
					parentNames.push(item.label);
				})
				this.dataForm.parentNames = parentNames.join(',');
				this.getContractType();
			}
		},
		// 获取签约类型
		getContractType(del = false) {
			let that = this;
			that.$request.post(
				'getContractTypeName',
				false,
				{
					idArr: that.dataForm.parentIds.join(',')
				},
				function (r) {
					if (r.code == "0") {
						let newContractType = [...that.dataForm.contractType]; // 拷贝原数组
						let newContractTypeName = that.dataForm.contractTypeName

						// 删除操作
						if (del) {
							newContractType = '';
							newContractTypeName = '';
						}
						if(newContractType.length > 0) {
							newContractType = [
								...new Set([
									...newContractType,
									...r.data.contractType.split(',').filter(item => item)
								])
							];
						} else {
							newContractType = r.data.contractType.split(',')
						}

						if (newContractTypeName.length > 0) {
							newContractTypeName += ',' + r.data.contractTypeName
						} else if (r.data.contractTypeName.length > 0) {
							newContractTypeName = r.data.contractTypeName
						}

						// 执行删除操作, 把原本的数据添加 org 部分
						if (del) {
							// 将 contractTypeOrg 数据与 newContractType 合并并去重
							newContractType = [
								...new Set([
									...newContractType,
									...that.contractTypeOrg.filter(item => item)
								])
							];

							// 将newContractTypeName 数据与 contractTypeOrgName 合并并去重
							newContractTypeName = newContractTypeName.split(',').filter(item => item).join(',');
						}

						that.dataForm.contractType = newContractType;
						that.dataForm.contractTypeName = newContractTypeName;

					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 移除事件
		handleRemove(tag) {
			if(this.dataForm.contractType.length == 0) {
				return
			}
			if(this.dataForm.parentIds.length == 0) {
				this.dataForm.contractType = this.contractTypeOrg;
				this.dataForm.contractTypeName = this.contractTypeOrgName;
				return
			}
			setTimeout(() => {
				let parentNames = [];
				this.$refs.myselect.selected.forEach(item => {
					parentNames.push(item.label);
				})
				this.dataForm.parentNames = parentNames.join(',');
				this.getContractType(true);
			}, 50);
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					// if(this.typeVisible && this.dataForm.contractType.length == 0) {
					// 	this.$message.error('请选择签约类型');
					// 	return
					// }
					let submitForm = this.dataForm;
					submitForm.contractType = submitForm.contractType.length > 0 ? this.dataForm.contractType.join(',') : '';
					// submitForm.parentIds = submitForm.parentIds.length > 0 ? this.dataForm.parentIds.join(',') : '';
					// submitForm.parentNames = submitForm.parentIds.length > 0 ? submitForm.parentNames : '';
                    submitForm.parentIdsTemp = submitForm.parentIds.length > 0 ? this.dataForm.parentIds.join(',') : '';
					submitForm.parentNamesTemp = submitForm.parentIds.length > 0 ? submitForm.parentNames : '';
                    submitForm.parentIds = "";
                    submitForm.parentNames = "";
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						submitForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getDealerList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000),
		// 检查是否禁用
		changeRowDisabled(key) {
			if (this.type === 'check') {
				// 查看
				return key !== 'submit';
			} else if (this.type === 'edit') {
				// 编辑
				if (key === 'submit') {
					return true;
				}
				return key === 'contractLevel' || key === 'contractType';
			} else if (this.type === 'add') {
				// 新增
				return key === 'submit';
			} else if (this.type === 'updateAgent'){
				// 变更
				if (key === 'submit') {
					return true;
				}
				return !(key === 'parentIds' || key === 'remark');
			}
		},
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 20px;
	.el-select{
		width: 100%;
	}
}
.submit-btn{
	display: block;
	width: 60%;
	margin: 0 auto;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
	line-height: 24px !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 0px 20px 40px !important;
}
.flex{
	display: flex;
}
.flex-1{
	flex: 1;
	box-sizing: border-box;
	padding-right: 15px;
}
.my-select-dropdown .el-select-dropdown__item {
    padding-right: 40px !important;
}

table {
	width: 100%;
	border-collapse: collapse;
	font-size: 14px;
	color: #606269;
	table-layout: fixed;
	margin-top: 5px;
	text-align: center;
}

table, tr, td {
	border: 1px solid #999999;
	white-space: pre-wrap; /* 保留空白符序列，但是正常地进行换行 */
	word-wrap: break-word; /* 在长单词或 URL 地址内部进行换行 */
}
.custom-row {
	height: 40px; /*设置行高为50像素*/
}
</style>
